@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.containerLoading {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1000;
  height: 100vh;
  background: white;
  .loaderPDFBtn {
    text-align: center;
    background: #FFFFFF;
    padding: 19px 38px;
    border: 1px solid black;
    border-radius: 14px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    &:hover {
      background: #E6E6E6;
    }
  }
  .loaderPDFDesc {
    font-family: 'Nunito Sans', sans-serif;
    margin-bottom: 40px;
    text-align: center;
    .loaderPDFdesc_title {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 6px;
    }
    .loaderPDFdesc_subTitle {
      font-weight: 400;
      line-height: 26px;
      font-size: 16px;
    }
  }

  .loaderButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    .loaderPDFBtn_prev {
      text-align: center;
      background: white;
      padding: 19px 38px;
      border-radius: 14px;
      font-family: 'Nunito Sans', sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #151515;
      border: 1px solid #151515;
      &:hover {
        background: #E6E6E6;
      }
    }
    .loaderPDFBtn_load {
      text-align: center;
      background: #333333;
      padding: 19px 38px;
      border-radius: 14px;
      font-family: 'Nunito Sans', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: white;
      &:hover {
        background: #151515;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .containerLoading {
    padding: 112px 12px 0px 12px;
    .loaderPDFBtn {
      width: 100%;
      padding: 12px 0px;
    }
    .loaderButtons {
      display: flex;
      flex-direction: column;
      width: 100%;
      .loaderPDFBtn_prev {
        width: 100%;
      }
      .loaderPDFBtn_load {
        width: 100%;
      }
    }
  }
}