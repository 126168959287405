@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.item,
.item-location,
.item-location-v2,
.item-location-hide,
.item-object,
.item-flat,
.item-infrastructure,
.item-trim,
.item-process,
.item-banks,
.item-contacts{
    padding: 64px 60px;
    width: 297mm;
    height: 210mm;
    box-sizing: border-box;
    z-index: 10000;
    background: #FEFEFE;
    page-break-after: always;
    margin: 0;
}

@media print{@page {size: landscape}}

@page {
    size: A4 landscape;
    margin: 0;
}

@media print {
    html, body {
        width: 297mm;
        height: 210mm;
    }
    .item {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}

.pdf-container {
    font-family: "Montserrat", 'sans-serif';
}

.pdf-container-loading {
    position: fixed;
    overflow: hidden;
    background-color: #FFFFFF;
    z-index: 15;
}

.item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
}

.item-infrastructure {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
}

.item-location {
    display: flex;
    flex-direction: column;
    position: relative;
}

.item-location-v2{
    position: relative;
}

.item-location.preferences .item-photos {
    display: flex;
    flex-direction: column;
    max-height: 50%;
}

.item-location.mixed .item-photos {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    max-height: 50%;
}

.item-location.preferences .item-photos .photos{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    grid-template-rows: 1fr;
}

.item-location.mixed .item-photos .check_ul-pdf li {
    font-size: 12px;
    line-height: 16px;
    margin: 10px;
    min-height: 20px;
    font-family: Montserrat, serif;
    font-weight: 400;
}

.main-photo {
    height: 100%;
    width: 100%;
}

.main-desc {
    font-family: Montserrat, serif;
}

.main-desc__title {
    font-size: 42px;
    font-weight: 600;
    line-height: 43.88px;
    text-align: left;
}


.main-desc__class-text.main,
.main-desc__deadline-text.main,
.main-desc__district-text.main{
    font-size: 24px;
    font-weight: 600;
    text-align: left;
}

.main-desc__class-desc.desc,
.main-desc__deadline-desc.desc,
.main-desc__district-desc.desc {
    font-family: Montserrat, serif;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
}

.desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 29.26px;
    text-align: left;
    color: #4D4D4D;
}

.item,
.item-infrastructure{
    padding: 0;
}

.item-location-hide .item-content{
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
}

.item-location-hide .item-content .item-content__text {
    font-size: 14px;
}

.item-location-v2 .item-content__text {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    -webkit-column-rule: 1px solid transparent;
    -moz-column-rule: 1px solid transparent;
    column-rule: 1px solid transparent;
}

.item-content__texts{
    display: flex;
    flex-direction: column;
}

.item-location-hide .item-content__text:not(:last-child){
    margin-bottom: 30px;
}

.item-location-v2 .item-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.item-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.item-head__title {
    font-size: 42px;
    font-weight: 600;
    line-height: 70px;
    text-align: left;
    color: #151515;
    font-family: 'Montserrat', serif;
    position: relative;
    bottom: 10px;
}

.main-photo__desc {
    font-weight: 400;
    line-height: 22px;
    margin-top: 30px;
    color: #4D4D4D;
    column-width: 390px;
}

.process-content {
    line-height: 32px;
    font-family: Montserrat, serif;
}

.process-item__title {
    font-size: 18px;
    line-height: 33.88px;
    margin-top: 10px;
    font-family: Montserrat, serif;
    font-weight: 600;
}

.process-item__desc {
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 22px;
}

.head-contacts__mail {
    font-size: 14px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: right;
    color: #A6A6A6;
    text-decoration: underline;
}

.head-contacits__group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.head-contacts__tel {
    font-size: 20px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: left;
    color: #A6A6A6;
}

.head-contacts__agent{
    font-size: 18px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: left;
    color: #A6A6A6;
    text-transform: uppercase;
}

.item-photos__elem .main-photo{
    width: 847px;
    height: 470px;
}

.head-contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.num {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: right;
    margin-top: 48px;
    color: #4D4D4D;
    position: absolute;
    bottom: 60px;
    right: 60px;
}

.item-list {
    list-style-type: disc;
    width: 130%;
    overflow: hidden;
    list-style-position: inside;
    margin-top: 30px;
}

.lists {
    display: flex;
    flex-direction: row;
}

.lists .item-list:first-child {
    margin-right: 4px;
}

.item-list li::marker  {
    color: #4D4D4D;
}

.item-list__item {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #4D4D4D;
}

.item-object .item-content{
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-rows: auto;
    gap: 25px;
}

.item-object .item-content-flex {
    display: flex;
    flex-direction: column;
}

.item-object .item-content-flex .item-object__photo:last-child{
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-rows: auto;
    gap: 25px;
}

.item-object .item-photo-only {
    display: flex;
}

.item-object .item-photo-only .item-object__photo {
    display: grid;
    grid-auto-columns: 1fr;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-rows: auto;
    gap: 55px;
}

.item-object .item-content .item-object__photo .object-params {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
}

.item-object .item-content .item-object__photo .object-params .object-list{
    display: grid;
    grid-template-rows: repeat(3, minmax(0, 1fr));
    margin-top: 25px;
    margin-right: 25px;
}

.item-object .item-content .item-object__photo .object-params .object-list__item {
    display: flex;
    flex-direction: column;
    font-family: Montserrat, serif;
}

.item-object .item-content .item-object__photo .object-params .object-list__item:not(:last-child){
    margin-bottom: 25px;
}
.item-object .item-content .item-object__photo .object-params .object-list__item .object-list__item-text{
    color: #151515;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    white-space: nowrap;
}

.item-object .item-content .item-object__photo .object-params .object-list__item .object-list__item-desc{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #4D4D4D;
    white-space: nowrap;
}

.item-flat .item-content {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
}

.item-pictures-sub {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.item-pictures {
    width: 100%;
}

.schema-flat {
    margin-bottom: 20px;
}

.compass {
    border-radius: 90px;
    background: #D9D9D9;
    box-sizing: border-box;
    clip-path: circle(50%);
    height: 7em;
    width: 7em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 25.71px;
}

.schema-floor{
 width: 200px;
    height: 120px;
}

.item-prices__total {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
}

.item-prices__main,
.item-prices__sub {
    display: flex;
    flex-direction: column;
    font-family: Montserrat, serif;
}

.item-params {
    font-family: Montserrat, serif;
}

.item-prices__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6px;
    height: 100%;
    font-family: Montserrat, serif;
}

.item-prices__total-price {
    font-size: 34px;
    font-weight: 600;
    text-align: center;
    color: #151515;
    line-height: 30px;
    margin-right: 28px;
}

.item-prices__total-desc {
    font-size: 20px;
    line-height: 15px;
}

.item-prices__sub-desc {
    font-weight: 400;
    color: #4D4D4D;
}

.item-prices__total {
    padding: 14px;
    background: #E6E6E6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    width: 100%;
}

.item-prices__total .item-prices__sub {
    background: #FFFFFF;
    padding: 16px 20px;
    border-radius: 5px;
}

.item-prices__sub-price {
    color: #151515;
    font-size: 16px;
    font-weight: 600;
}

.item-flat .item-prices {
    display: flex;
    flex-direction: column;
}

.item-flat .item-prices .params-list.first {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    gap: 20px;
    align-items: center;
    padding: 26px 0px;
}

.item-flat .item-prices .params-list.second{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 20px;
    align-items: center;
    padding: 26px 0px;
}

.map-title__metro {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
}

.item-flat .item-prices .params-list .params-list__item.alone{
    grid-column: 1 / 4;
}

.item-flat .item-prices .params-list .params-list__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.item-flat .item-prices .params-list .params-list__item .params-list__item-text{
    color: #151515;
    font-size: 16px;
    font-weight: 600;
}

.item-flat .item-prices .params-list .params-list__item .params-list__item-desc{
    color: #4D4D4D;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 400;
}

.item-flat .decorator {
    background: #E6E6E6;
    height: 1px;
}

.map-title__content {
    color: #151515;
    font-size: 42px;
    font-weight: 600;
    font-family: Montserrat, serif;
}

.map-title__address {
    color: #4D4D4D;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
}

.map-title__address .map-title__address-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: left;
    color: #4D4D4D;
}

.map-title__metro-text:nth-child(1):before {
    background: #0072BA;
}

.map-title__metro-text:nth-child(2):before {
    background: #BD1018;
}

.map-title__metro-text:nth-child(3):before{
    background: #915133;
}

.map-title__metro-text:nth-child(4):before{
    background: #EA5B0C;
}

.map-title__metro-text {
    font-size: 12px;
}

.metro-time,
.map-title__metro-text{
    white-space: nowrap;
}

.metro-time {
    font-weight: 200;
    color: #A6A6A6;
}

.map-title__metro-text:after {
    content: '';
    width: 1px;
    height: 16px;
    display: block;
    background: #A6A6A6;
    margin: 0 8px;
}

.map-title__metro-text:before {
    margin-right: 8px;
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    background: #0072BA;
    clip-path: circle(50%);
}

.map-title__metro-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px 0px;
}

.map-title__metro-text svg {
    margin: 0 6px;
}

.map-content {
    padding: 40px;
    margin-bottom: 67px;
}

.map-prefer__item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.map-prefer__item svg {
    margin-right: 20px;
}

.process-content {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 10px;
}

.trim-content {
    display: grid;
    grid-template-columns: repeat(3, minmax(400px, 1fr))
}

.banks-content {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 40px 60px;
}

.trim-photo{
    margin-bottom: 20px;
}

.trim-item__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #151515;
    font-family: Montserrat, serif;
}

.trim-list__elem p{
    font-family: Montserrat, serif;
    font-size: 15px;
    font-weight: 400;
    align-content: center;
    color: #4D4D4D;
    overflow: hidden;
}

.trim-head,
.process-head,
.banks-head,
.head-contacts {
    color: #151515;
    font-size: 42px;
    font-weight: 600;
}

.banks-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.banks-item svg {
    margin-right: 8px;
}

.banks-item__group {
    display: flex;
    flex-direction: column;
}

.banks-item__group-bank {
    color: #4D4D4D;
    font-size: 18px;
    font-weight: 400;
}

.banks-item__group-desc {
    color: #4D4D4D;
    font-size: 26px;
    font-weight: 600;
}

.contacts-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.agent-name {
    color: #151515;
    font-size: 20px;
    font-weight: 600;
}

.agent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.card-title__name {
    color: #151515;
    font-size: 42px;
    font-weight: 600;
    line-height: normal;
    text-align: right;
}

.card-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
}

.card {
    margin-top: 30px;
}

.contacts-content .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    height: 100%;
}

.card-decorator {
    margin: 0 60px;
    background: #4D4D4D;
    height: 100%;
    width: 1px;
}

.card-contacts {
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
}

.card-social__item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-social__item:not(:last-child){
    margin-bottom: 6px;
}

.agent-photo{
    /*width: 250px;*/
    /*height: 250px;*/
    /*margin-bottom: 30px;*/
    /*margin-top: 10px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
        /*width: 300px;*/
        /*height: 300px;*/
        margin-bottom: 30px;
        margin-top: 10px;
        border-radius: 191px;
    }
}

.card-socail__item-name {
    margin: 0 8px;
    font-size: 16px;
    font-weight: 600;
    color: #151515;
}

.card-socail__item-alt,
.card-tel__mail{
    text-decoration: underline;
}

.card-socail__item-alt {
    font-size: 14px;
}

.card-name,
.card-tel__tel,
.card-tel__mail {
    color: #151515;
    font-size: 20px;
    font-weight: 600;
}

.card-tel__mail {
    font-size: 18px;
}

.item-contacts.v3 .contacts-content {
    height: 100%;
    align-items: center;
    justify-content: center;
}

.map-photo {
    height: 100%;
}

.map-prefer__item--text {
    white-space: nowrap;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 300;
    line-height: 19.26px;
}

.map-pdf .container-map .custom-select {
    display: none;
}

.map-pdf .container-map .map-content-yamaps {
    height: 210mm;
}

.map-pdf {
    height: 100%;
    width: 100%;
}

.pdf-style .ymaps-2-1-79-placemark-overlay{
    display: none;
}

.pdf-style .ymaps-2-1-79-placemark-overlay:first-child{
    display: inline;
}

.icon-place-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.infrastructures-item__content {
    color: #4D4D4D;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
}