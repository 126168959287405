.loaderPDFProgressBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 320px;
  padding: 2px 4px;
  background: #FFFFFF;
  border-radius: 24px;
  border: 2px solid #151515;
  overflow: hidden;
  p {
    font-size: 18px;
    font-family: "Nunito Sans", 'sans-serif';
    margin-top: 10px;
  }
  .loaderPDFProgressBar_line {
    background: #151515;
    //transition: width 5s ease-in-out;
    box-sizing: border-box;
    border-radius: 24px;
    height: 30px;
    width: 100%;
    @keyframes progressBar {
      0% {
        width: 0;
      }

      100% {
        width:100%;
      }
    }
  }
}

.notify {
  box-sizing: border-box;
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(230, 230, 230, 0.5);
  z-index: 1005;
  width: 500px;
  padding: 1px 1px 1px 4px;
  border-radius: 10px;
  .notifySub {
    border-radius: 10px;
    padding: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    background: #FFFFFF;
    width: 100%;
    .notify_text {
      font-family: "Nunito Sans", 'sans-serif';
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 768px){
  .loaderPDFProgressBar {
    width: 200px;
    margin-bottom: 112px;
    .loaderPDFProgressBar_line{
      height: 20px;
    }
  }

  .notify {
    width: 300px;
  }
}