@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.custom-select {
    position: relative;
    width: 352px;
    max-width: 100%;
    font-size: 14px;
    position: absolute;
    z-index: 1001;
    left: 24px;
    top: 24px;
}

.select-button {
    width: 100%;
    background-color: #fff;
    padding: 6px 12px;
    cursor: pointer;
}

.select-button:focus {
    outline: none;
}

.select-button::-moz-focus-inner {
    border: 0;
}

.select-button.acitve {
    border-color: #FDD7CE;
}

.select-button:hover {
    background: #FFFFFF;
}

.selected-value {
    text-align: left;
}

.arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transition: transform ease-in-out 0.3s;
}

.select-dropdown {
    position: relative;
    list-style: none;
    padding: 10px;
    max-height: 232px;
    transition: 0.5s ease;
    border-radius: 14px;
    transform: scaleY(0);
    opacity: 0;
    right: 0;
    visibility: hidden;
    margin-right: 12px;
    overflow-y: auto;
}

.container-ul {
    position: absolute;
    background: white;
    visibility: hidden;
    max-height: 232px;
    opacity: 0;
    right: 0;
    min-height: 232px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.select-dropdown li {
    position: relative;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.select-dropdown li label {
    width: 100%;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #151515;
    font-size: 18px;
    font-family: "Nunito Sans", 'sans-serif';
}

.select-dropdown::-webkit-scrollbar-track {
    background: white;
    border-radius: 25px;
    margin: 14px;
}

.select-dropdown::-webkit-scrollbar-thumb {
    background: #A6A6A6;
    border-radius: 8px;
}

.select-dropdown li:hover,
.select-dropdown input:checked ~ label {
    background-color: #F2F2F2;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
}

.select-dropdown input:focus ~ label {
    background-color: #dfdfdf;
}

.select-dropdown input[type="radio"] {
    position: absolute;
    left: 10px;
    background: black;
    accent-color: #333333;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

.select-dropdown input[type='radio'] + label:not(:last-child){
    margin-left: 10px;
}

.custom-select.active .arrow {
    transform: rotate(180deg);
}

.custom-select.active .select-dropdown {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    border-radius: 14px;
}

.custom-select.active .container-ul {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    border-radius: 14px;
}

.select-dropdown::-webkit-scrollbar {
    width: 4px;
    background-color:white;
}

.option[selected] {
    background-color: red;
    font-weight: bold;
    text-decoration: underline;
}

.ymaps-2-1-79-balloon_to_top .ymaps-2-1-79-balloon__tail {
    display: none;
}

.iconCaption {
    white-space: nowrap;
    position: relative;
    font-size: 10px;
    top: 48px;
    right: 44px;
    font-weight: bold;
}

.mobile-balloon-container .ymaps-2-1-79-balloon__close-button {
    position: absolute;
    right: 0;
    margin: 4px 8px;

}

.mobile-balloon-container .ymaps-2-1-79-balloon__close-button:hover {
    background: url("./../svg/close-element-main.svg") 50%;
    background-repeat: no-repeat;
    margin: 4px 8px;
}