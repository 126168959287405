@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    position: initial;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

body {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body.modal {
    position: fixed;
}

header, main {
    background: #FFFFFF;
    font-family: "Nunito Sans", sans-serif;
}

footer {
    font-family: "Nunito Sans", sans-serif;
}

.swiper {
    border-radius: 24px;
    border: 1px solid #E6E6E6;
}

.mobile-variant .swiper {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.thumbailsSlider {
    border: 0;
    border-radius: 0;
}

.thumbailsSlider .swiper-slide {
    opacity: 0.7;
    overflow: hidden;
    cursor: pointer;
}

.thumbailsSlider .swiper-slide:hover {
    opacity: 1;
}

.thumbailsSlider .swiper-slide-thumb-active {
    border: 4px solid #4D4D4D;
    overflow: hidden;
    opacity: 1;
}

.swiper-horizontal {
    width: 100%;
}

.swiper-pagination-fraction {
    position: absolute;
    bottom: 20px;
    z-index: 9;
    display: block;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: fit-content;
    padding: 8px 12px;
    border-radius: 8px;
    background: #FAFAFA;
    letter-spacing: -2px;
    font-size: 20px;
}

.mobile-variant .swiper-pagination-fraction {
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 4px;
}

.mobile-variant .swiper-pagination-fraction .swiper-pagination-current {
    font-weight: bold;
    color: #151515;
    margin: 0px 0.4px;
}

.mobile-variant .swiper-pagination-fraction .swiper-pagination-total {
    font-weight: bold;
    color: #AAAAAA;
    margin: 0px 0.4px;
}

.mobile-variant .swiper-pagination-fraction #text {
    font-weight: bold;
    color: #151515;
}

.swiper-pagination-total {
    font-weight: 300;
}

.swiper-button-next {
    right: 40px !important;
}

.swiper-button-next::after {
    content: url("./../public/icons/next_slide.svg") !important;
    line-height: 0 !important;
    background: white;
    padding: 18px;
    border-radius: 50%;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .12);
}

.swiper-button-next::after:hover {
    background: #000;
}

.swiper-button-prev {
    left: 40px !important
}

.swiper-button-prev::after {
    content: url("./../public/icons/next_slide.svg") !important;
    line-height: 0 !important;
    transform: rotatey(-180deg);
    background: white;
    padding: 18px;
    border-radius: 50%;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .12);
}

.check_ul li {
    padding-left: 36px;
    position: relative;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.check_ul li::before {
    position: absolute;
    content: url("./../public/icons/li_icon.svg");
    top: 0;
    left: 0;
    bottom: 0;
}

.check_ul-pdf li {
    padding-left: 20px;
    position: relative;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #4D4D4D;
    font-family: Montserrat, serif;
}

.check_ul-pdf li::before {
    position: absolute;
    content: url("./../public/icons/сheck-ul-pdf.svg");
    top: 14px;
    left: 0;
    scale: 0.8;
}

.item-location.mixed .item-photos .check_ul-pdf li::before {
    padding-left: 10px;
}

.yandexMapWrapper ymaps[class$="balloon__content"] {
    background: #FFFFFF;
    height: 100%;
}

.yandexMapWrapper ymaps[class$="balloon__content"] > ymaps {
    width: auto !important;
    height: auto !important;
}

.yandexMapWrapper ymaps[class$="custom-scroll"] {
    border-radius: 20px;
}

.yandexMapWrapper ymaps[class$="balloon__layout"] {
    padding: 5px;
    background: #FFFFFF;
    border-radius: 20px;
    border: none;
    width: 300px;
    height: auto;
}

.yandexMapWrapper ymaps[class$="balloon__tail"]::after {
    background: #FFFFFF;
    width: 15px;
    height: 15px;
}

.yandexMapWrapper ymaps[class$='balloon__close-button'] {
    width: 24px;
    height: 24px;
    padding: 5px;
}

.yandexMapWrapper ymaps[class$="balloon__content"] > ymaps > ymaps {
    display: flex;
    gap: 10px;
    flex-direction: column;
    font-size: 14px;
}

.yandexMapWrapper ymaps[class$="balloon__content"] > ymaps > ymaps > topClass {
    display: flex;
    gap: 10px;
}

.yandexMapWrapper ymaps[class$="balloon__content"] > ymaps > ymaps > flex {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
}

.yandexMapWrapper ymaps[class$="balloon__content"] > ymaps > ymaps > flex > icon {
    width: 14px;
    height: 22px;
    background-image: url('../public/icons/location.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 14px;
}

.span-mobile-map-place {
    width: 14px;
    height: 22px;
    background-image: url('../public/icons/location.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 14px;
}

.balloonContentBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.yandexMapWrapper ymaps[class$="balloon__content"] > ymaps > ymaps > topClass > ballonIcon {
    width: 30px;
    height: 30px;
    min-width: 30px;
}

.span-mobile-map-object{
    width: 30px;
    height: 30px;
    min-width: 30px;
}

.balloon-mobile-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.yandexMapWrapper ymaps[class$="balloon__content"] > ymaps > ymaps > topClass > span {
    width: 30px;
    height: 30px;
    min-width: 30px;
}


.yandexMapWrapper ymaps[class$="balloon__content"] > ymaps > ymaps > topClass > textName {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.close:hover {
    cursor: pointer;
    opacity: 1;
}
.close {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 1px solid #A6A6A6;
    border-radius: 50%;
}

.close:before, .close:after {
    position: absolute;
    top: 25%;
    left: 50%;
    content: ' ';
    height: 10px;
    width: 1px;
    background-color: #A6A6A6;
    transform: translate(-50%, -50%);
}

.close:hover::before,
.close:hover::after {
    background-color: #F54012;
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}

.modal-container {
    width: 100%;
    height: 100%;
    z-index: 20000;
    display: flex;
    align-items: flex-end;
    backdrop-filter: blur(5px);
    background: rgba(0,0,0,0.5);
}

.modal-content {
    width: 100%;
}

button:hover {
    background: #151515;
    cursor: pointer;
}

hr {
    margin: 0;
}

.close-about.active{
    animation: animation-spoiler-close 500ms cubic-bezier(0.9, 0.7, 0.5, 0.9) forwards;
}

.close-about {
    animation: animation-spoiler-open 500ms cubic-bezier(0.9, 0.7, 0.5, 0.9) forwards;
}

.yamaps.active {
    animation: animation-spoiler-close 500ms cubic-bezier(0.9, 0.7, 0.5, 0.9) forwards;
}

.yamaps {
    animation: animation-spoiler-open 500ms cubic-bezier(0.9, 0.7, 0.5, 0.9) forwards;
}

@keyframes animation-spoiler-close{
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
@keyframes animation-spoiler-open{
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.text-desc.fade-in {
    opacity: 0;
    animation: fadeInOpen ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}

.text-desc {
    opacity: 0;
    animation: fadeInClose ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}

@keyframes fadeInOpen {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInClose {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 768px){

    .share-desktop {
        display: none;
    }

    .modal {
        display: block;
    }

    .bg-cover {
        height: 80vw;
    }

    .swiper-slide .bg-cover {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .custom-select {
        position: static !important;
        width: 100% !important;
        margin-bottom: 12px;
    }

    .container-ul {
        width: 100%;
        max-height: 300px;
    }

    .select-dropdown,
    .container-ul {
        max-height: 190px !important;
        min-height: 190px !important;
    }

    .ymaps-2-1-79-inner-panes {
        border-radius: 12px;
    }

    .ymaps-2-1-79-map {
        width: 100%;
    }

    .ymaps-2-1-79-balloon {
        display: none;
    }

    .swiper-button-prev,
    .swiper-button-next{
        display: none !important;
    }
    /*когда будут настройки пдф*/
    /*.spoiler-span {*/
    /*    display: none;*/
    /*}*/
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .ProductSlider_sliderImage__2W0MR {
        max-height: 28rem;
    }
    .bg-cover {
        max-height: 28rem;
    }
}

@media (min-width: 768px) and (max-width: 1279.98px) {
    .wrapper {
        max-width: 1280px;
        width: 100%;
        padding: 0px 12px;
    }

    footer {
        max-width: 1280px !important;
        width: 100% !important;
        padding: 0px 12px !important;
    }
}

.links-copy__element-pre {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

button:hover .svg-link path{
    fill: #F54012;
}

.spoiler-span:hover path{
    fill: #F54012;
}

.slovo-container:hover .slovo {
    color: #F54012;
}

.slovo-container:hover .spoiler-span path{
    fill: #F54012;
}

.slovo-container {
    display: flex;
}

.slovo-container:hover slovo {
    text-decoration-color: #F54012;
}

@media (hover:none) {
    .spoiler-span:hover path{
        fill: #151515;
    }

    .slovo-container:hover .slovo {
        color: #151515;
    }

    .slovo-container:hover .spoiler-span path{
        fill: #151515;
    }
}

.yamaps:hover path{
    fill: #F54012;
}

.share-desktop {
    -webkit-box-shadow: 4px 4px 11px 0px rgba(35, 43, 49, 0.28);
    -moz-box-shadow: 4px 4px 11px 0px rgba(35, 43, 49, 0.28);
    box-shadow: 4px 4px 11px 0px rgba(35, 43, 49, 0.28);
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
}

.share-mobile {
    z-index: 15000;
}

.infrastructures-ul {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 0.3fr));
}

.infrastructers-border:last-child{
    border: none;
}

.infrastructers-border {
    -webkit-column-gap: 8px;
}

.select-variants {
    position: relative;
    left: 0;
    top: 0;
    min-width: 40px;
    color: #4D4D4D;
}

.select-variants.active {
    color: #151515;
}

.select-variants.active::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: #F54012;
    position: absolute;
    left: 0;
    bottom: -1px;
}

.ymaps-2-1-79-user-selection-none {
    cursor: pointer;
}

.ymaps-2-1-79-user-selection-none{
    display: block !important;
}